<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Courses</a-breadcrumb-item>
        <a-breadcrumb-item><router-link :to="'/client/group_courses'+loginAs"> Group Courses</router-link></a-breadcrumb-item>
        <a-breadcrumb-item> {{group?.name}}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h3>{{group?.name}}</h3>
          <div class="add">
            <a-button type="primary" @click="courseDrawer"> <UserAddOutlined /> Add Course </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="groupcourse-Lists">
        <div class="body">
          <a-table :columns="courseColumns" :data-source="assignedCourses" class="ant-table-striped"
            :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :pagination="{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
            }"
            rowKey="_id"
          >
            <template #price="{ text: price }">
              <span>
                <a-tag color="green">£ {{ price }} </a-tag>
              </span>
            </template>
            <template #action="{ record, index }">
              <div class="actionblock">
                <img src="../../../assets/images/deleteicon.svg" @click="confirmDeleteCourse(record._id, index)" />
              </div>
            </template>
          </a-table>
        </div>
      </a-row>
    </div>
    <a-drawer title="Course List" placement="right" :closable="true" v-model:visible="visible" @ok="closeCourseListModal" class="courselistDrawer" :maskClosable="false" :destroyOnClose="true" :width="500">
        <a-checkbox v-model:checked="selectAllCourse" :indeterminate="indeterminate" @change="onCheckAllChange">
          All Courses
        </a-checkbox>
        <a-checkbox-group v-model:value="selectedCourse" :options="availableCourse"/>
        <div class="footersec">
          <a-button type="primary" @click="assignCourse" :loading="loading">Assign</a-button>
        </div>
      </a-drawer>
  </div>
</template>

<script lang="ts">
import { defineComponent, createVNode, ref, reactive, watch, toRefs, onMounted } from 'vue'
import { HomeOutlined, UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import groupService from '../../../services/group'
import commonServices from '../../../services/common'

import ICourse from '../../../interface/course'
interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    UserAddOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const route = useRoute()
    const groupId = ref<string>(route.params.id as string)
    const group = ref<Group>()
    const unassignedCourses = ref<Array<ICourse>>([])
    const assignedCourses = ref<Array<any>>([])
    const availableCourse = ref([])
    const loading = ref<boolean>(false)
    const courseColumns = [
      {
        title: 'Course Name',
        dataIndex: 'course.title',
        onFilter: (value, record) => record.course.title.indexOf(value) === 0,
        sorter: (a, b) => a.course.title.length - b.coursename.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Type',
        dataIndex: 'course.type'
      },
      {
        title: 'Price',
        dataIndex: 'course.price',
        onFilter: (value, record) => record.course.price.indexOf(value) === 0,
        sorter: (a, b) => a.course.price.length - b.course.price.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'price' }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroupDetails = async () => {
      try {
        const responce = await groupService.getGroupDetails(groupId.value)
        group.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const removeCourse = async (id, index) => {
      try {
        await groupService.deleteGroupCourse(id)
        assignedCourses.value.splice(index, 1)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCourse = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await removeCourse(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const visible = ref<boolean>(false)
    const courseDrawer = () => {
      visible.value = true
    }
    const state = reactive({
      indeterminate: false,
      selectAllCourse: false,
      selectedCourse: []
    })
    const closeCourseListModal = () => {
      visible.value = false
    }
    const getUnGroupedCourses = async () => {
      try {
        availableCourse.value = []
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await groupService.getUnGroupedCourses(groupId.value, clientId)
        unassignedCourses.value = responce.data
        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getGroupedCourses = async () => {
      try {
        availableCourse.value = []
        const responce = await groupService.getGroupedCourses(groupId.value)
        assignedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const assignCourse = async () => {
      try {
        const enroll = []
        loading.value = true
        for (const courseId of state.selectedCourse) {
          enroll.push({ group: groupId.value, course: courseId })
        }
        await groupService.groupMultipleCourse(enroll)
        loading.value = false
        closeCourseListModal()
        getGroupedCourses()
        notify('Success', 'Course Assigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    watch(() => state.selectedCourse, (val) => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.selectAllCourse = val.length === availableCourse.value.length
    })
    onMounted(() => {
      getGroupDetails()
      getUnGroupedCourses()
      getGroupedCourses()
    })
    return {
      courseColumns,
      confirmDeleteCourse,
      visible,
      notify,
      courseDrawer,
      onCheckAllChange,
      ...toRefs(state),
      closeCourseListModal,
      group,
      assignCourse,
      availableCourse,
      loading,
      assignedCourses,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .ant-col{
          display: flex;
    justify-content: space-between;
    }
    h3 {
      font-size: 16px;
      text-transform: capitalize;
      color: #646464;
      font-family: "TT Norms Pro Medium";
      margin: 0;
    }
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .groupcourse-Lists {
    padding: 10px;
    .body {
      background: #ffffff;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      font-family: "TT Norms Pro Medium";
      .ant-table {
        color: #646464;
        .ant-table-thead {
          tr {
            th {
              background: #e6e6e6;
              color: #646464;
              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              border-bottom: none;
              &:nth-child(2) {
                color: #38B6FF;
              }
            }
          }
          .actionblock{
            img{
              cursor: pointer;
            }
          }
        }
      }
      .ant-pagination {
        .ant-pagination-options {
          // display: none;
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          border-radius: 50%;
          background-color: #ededed;
          border: none;
        }
        .ant-pagination-item {
          background-color: #ededed;
          margin-right: 0px;
          border: none;
          font-family: "TT Norms Pro Regular";
        }
        li:nth-child(2) {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        li:nth-last-child(3n) {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        .ant-pagination-next {
          margin-left: 8px;
        }
        .ant-pagination-item-active {
          background-color: #ededed;
          a {
            background: #6f64f8;
            color: #fff;
            border-radius: 50%;
          }
        }
      }
      .totalentries {
        font-family: "TT Norms Pro Regular";
        text-align: left;
        margin: -40px 0 0 0;
        color: #646464;
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
